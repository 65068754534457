<template>
  <Layout tituloPagina="Entradas | Nueva entrada de articulos">
      <div class="row">
        <div class="col-md-10">
          <div class="card">
            <div class="card-header justify-content-between d-flex align-items-center">
              <h5 class="card-title">
                {{ modo == 'nuevo' ? 'Nueva entrada' : 'Visor de entrada' }} de artículos
              </h5>
              <div>
                <div class="dropdown">
                  <a
                    class="dropdown-toggle"
                    href="javascript: void(0);"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <eva-icon
                      name="more-horizontal-outline"
                      data-eva-width="20"
                      data-eva-height="20"
                      :class="{
                        'fill-dark': $store.state.layout.layoutMode == 'light',
                        'fill-white': $store.state.layout.layoutMode == 'dark'
                      }"
                    ></eva-icon>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        href="#"
                        class="dropdown-item"
                        onclick="return false"
                        @click="guardar()"
                        v-show="!bandera_spinner && modo == 'nueva'"
                      >
                        <i class="mdi mdi-content-save text-success"></i>
                        Dar entrada
                      </a>
                      <span class="dropdown-item disabled" v-if="bandera_spinner">
                        <i class="mdi mdi-concourse-ci mdi-spin"></i>
                        Dar entrada
                      </span>
                    </li>
                    <li>
                      <a
                        class="dropdown-item" href="#"
                        onclick="return false;" @click="cerrar()"
                      >
                        <i class="mdi mdi-close text-dark"></i>
                        Cerrar
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row" v-show="modo=='visor'">
                <div class="col-md-3">
                  <label for="idEntrada">ID de entrada al almacén</label>
                  <input name="idEntrada" type="text" 
                    class="form-control text-right" v-model="entrada.id_entalm"
                    readonly
                  />
                </div>
                <div class="col-md-offset-1 col-md-4">
                  <label for="nombreUsuario">Almacenista que registró la salida</label>
                  <input type="text" v-model="entrada.nombre_almacenista" 
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label for="tipo">Tipo de entrada</label>
                  <select name="tipo" v-model="entrada.tipo" class="form-select"
                    :disabled="modo=='visor'">
                    <option value="1">Entrada</option>
                    <option value="2" v-show="visibleStockInicial()">Stock inicial</option>
                  </select>
                </div>
                <div class="col-md-8">
                  <label for="notaEntrada">Nota</label>
                  <textarea
                    name="notaEntrada" v-model="entrada.nota"
                    class="form-control" :readonly="modo=='visor'"
                  ></textarea>
                </div>
              </div>
              <br>
              <div class="text-right">
                <button @click="mostrarMdlNuevaPartida()" class="btn btn-success btn-sm"
                  v-show="modo=='nueva'">
                  <i class="mdi mdi-plus-thick"></i>
                  Nueva partida
                </button>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-hover table-nowrap" style="min-width: 1200px">
                      <thead>
                        <tr>
                          <th style="width:40px"></th>
                          <th style="width: 50px">#</th>
                          <th style="width: 75px">Cantidad</th>
                          <th style="width: 90px">Unidad de medida</th>
                          <th>Nombre del artículo</th>
                          <th style="width: 200px">División</th>
                          <th style="width: 90px">Familia de artículo</th>
                          <th>Descripción del artículo</th>
                          <th style="width:100px;" class="text-center"
                            v-show="modo == 'nueva'">
                            Acción
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(partida,index) in entrada.partidas" :key="`partida-${index+1}`">
                          <td>
                            <div style="min-height: 30px; display: inline-block">
                              <a
                                href="#"
                                onclick="return false;"
                                @click="mostarGaleriaFotosArticulo(partida.id_articulo)"
                                v-if="partida.foto_principal"
                              >
                                <img
                                  :src="`${API}/articulos/${partida.id_articulo}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                                  style="width:30px; height:30px;"
                                  class="icon-articulo"
                                />
                              </a>
                              <img
                                :src="inventarioIcon"
                                style="width:30px; height:30px;"
                                v-if="!partida.foto_principal"
                              />
                            </div>
                          </td>
                          <td>{{index+1}}</td>
                          <td class="text-right">{{partida.cantidad}}</td>
                          <td>{{partida.abreviatura_unidad_medida}}</td>
                          <td>{{partida.nombre_articulo}}</td>
                          <td>{{partida.division}}</td>
                          <td class="text-right">{{partida.familia}}</td>
                          <td>{{partida.descripcion_articulo}}</td>
                          <td class="text-center" v-show="modo == 'nueva'">
                            <button class="btn btn-danger btn-sm"
                              @click="eliminarPartida(index)">
                              <i class="mdi mdi-trash-can-outline"></i>
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <div class="btn-group">
              <button class="btn btn-secondary" @click="cerrar()">
                <i class="mdi mdi-chevron-left"></i>
                Atras
              </button>
              <button
                class="btn btn-success"
                @click="guardar()"
                :disabled="bandera_spinner"
              >
                <i
                  class="mdi"
                  :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
                ></i>
                Dar entrada
              </button>
            </div>
          </div>
        </div>
      </div>

    <MdlNuevaPartidaEntrada ref="mdlNuevaPartidaEntrada"
      v-on:partida:lista="agregarPartida($event)"/>
    <cmp-galeria-fotos 
      ref="galeria_entradas_articulo"
      :data_gallery="`galeria-entradas-articulo`"
    />
    <br>
  </Layout>
</template>

<script>
import PeriodoInventarioSrv from '@/services/inventario/PeriodoInventarioSrv.js'
import EntradaSrv from '@/services/inventario/EntradaSrv.js'
import MdlNuevaPartidaEntrada from './MdlNuevaPartidaEntrada.vue'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import CmpGaleriaFotos from "../CmpGaleriaFotos.vue"
import Layout from "@/views/layouts/main.vue"
import { APIINV as API } from '@/API.js'

export default {
  components: {
    MdlNuevaPartidaEntrada, CmpGaleriaFotos, Layout
  },
  data: function () {
    return {
      modo: 'nueva',
      API : API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      entrada: {
        id: null,
        id_entalm: null,
        tipo: 1,      // 1 = Entrada, 2 = Stock inicial, 3 = Entrada por conversión de artículos
        nota: '',

        /**
         * Cada partida tiene la siguiente estructura
         * 
         * partidas: [
         *    {
         *      id_articulo: int,
         *      cantidad: float,
         *      id_unidad_medida: int,
         *      id_partida_reqart: int
         *    },
         *    ...
         * ]
         */
        partidas: []
      },
      
      periodo:{
        id: null,
        meses_periodo: 1,
        periodo_inicial: 1,
        periodo_final: 5
      },
      bandera_spinner: false
    }
  },
  created: function() {
    var self = this
    self.cargarPeriodoInventario()
    
    if(self.$route.params.id != undefined) {
      self.entrada.id = self.$route.params.id
      self.modo = 'visor'
      
      self.cargarEntrada()
    }
  },
  methods: {
    atras() {
      this.$router.go(-1)
    },

    agregarPartida(nuevaPartida) {
      var self = this

      let index = self.entrada.partidas.findIndex(function (partida) {
        return partida.id_articulo == nuevaPartida.id_articulo
      })
      
      if(index != -1) {
        iu.msg.warning('No se puede registrar una partida con el mismo artículo')
        return
      }

      self.entrada.partidas.push(nuevaPartida)
    },

    cargarEntrada() {
      let self = this
      let idEntrada = this.$route.params.id

      EntradaSrv.entradaJSON(idEntrada).then(response => {
        let entrada = response.data
        self.entrada = entrada
      })
    },

    cargarPeriodoInventario(){
      var self = this

      PeriodoInventarioSrv.periodoJSON().then(response => {
        self.periodo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el periodo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    eliminarPartida: function(index) {
      this.entrada.partidas.splice(index, 1)
    },

    mostarGaleriaFotosArticulo: function(idArticulo) {
      var self = this
      self.$refs.galeria_entradas_articulo.mostrarGaleria(idArticulo)
    },

    mostrarMdlNuevaPartida() {
      this.$refs.mdlNuevaPartidaEntrada.mostrar()
    },

    guardar(){
      var self = this

      self.bandera_spinner = true

      if(self.entrada.partidas.length == 0) {
        iu.msg.warning('No se puede realizar la entrada de artículos, ya que no se ha seleccionado ningún artículo')
        self.bandera_spinner = false
        return
      }

      let newEntrada = {
        id: self.entrada.id,
        id_entalm: self.entrada.id_entalm,
        tipo: self.entrada.tipo,
        nota: self.entrada.nota,
        partidas: []
      }

      self.entrada.partidas.forEach(partida => {
        let newPartida = {
          id_articulo: partida.id_articulo,
          cantidad: partida.cantidad,
          id_partida_reqart: partida.id_partida_reqart,
          id_familia: partida.id_familia
        }
        newEntrada.partidas.push(newPartida)
      })

      EntradaSrv.guardar(newEntrada).then(response => {
        iu.msg.success('Se guardó correctamente las entrada')
        self.$router.go(-1)
      }).catch(error => {
        let mensaje 
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo realizar la entrada'
        }
        iu.msg.warning(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    visibleStockInicial () {
      var self = this
      var fecha = new Date()

      let dia = fecha.getDate()

      let mes = fecha.getMonth()+1

      let numVec = 24 / self.periodo.meses_periodo;

      let arrayMes = []

      if(self.periodo.meses_periodo > 1) arrayMes.push(1)

      for (let a = 1; a <= numVec; a++) {
        let m = (self.periodo.meses_periodo*a) + 1

        if(m > 12) m = ((m + 11) % 12 + 1)

        arrayMes.push(m)
      }

      let mesAsig = arrayMes.find(m => m == mes)

      if(mesAsig !== undefined){
        if(dia >= self.periodo.periodo_inicial && dia <= self.periodo.periodo_final ) return true
        else return false
      } else { return false }
    }
  }
}
</script>

<style>
.icon-articulo {
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>